import React from "react";
import "./Footer.scss";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      starLogo: file(relativePath: { eq: "star-logo.png" }) {
        childImageSharp {
          fixed(width: 125, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="footer d-flex flex-row sm-fCol">
        <div className="flex-50 d-flex flex-column">
          <Img
            fixed={data.starLogo.childImageSharp.fixed}
            alt="star-terminal"
          />
        </div>
        <div className="flex-50 d-flex flex-column nav">
          <ul>
            {/* <li>Contact Us</li>
            <li>Legal Notice</li>
            <li>Privacy Notice</li>
            <li>Sitemap</li> */}
            {/* <li><FontAwesomeIcon icon={faLaptop} /></li> */}
          </ul>

          <p className="star">© STAR Services Group 2020 ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
