import React, { createContext, useState, useRef } from "react";

const ServiceContext = createContext();

export function ServiceProvider({ children }) {
    const [service, setService] = useState(null);

    const selectService = (serv) => {
        setService(serv);
    }

    return(
        <ServiceContext.Provider value={{ service, selectService }}>
            {children}
        </ServiceContext.Provider>
    )
}

export default ServiceContext;